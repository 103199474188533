import Handbook from '@blissbook/common/handbook'
import HandbookSignature from '@blissbook/common/handbook/signature'
import Organization from '@blissbook/common/organization'
import { Person } from '@blissbook/common/person'
import { Session } from '@blissbook/common/session'
import { User } from '@blissbook/common/user'
import type { BlissbookTocItem } from '@blissbook/lib/blissbook'
import map from 'lodash/map'

export const isLive = !document.querySelector('header.blissbook-header') // Is this the live version of the handbook (not preview, not signed, not historical, not view-as)
export const isPreview = !!document.getElementById('preview-header')

export type BlissbookHandbookRendered = Omit<IHandbook, 'toc'> & {
  canPreview?: boolean
  handbookUrl: string
  isPublic?: boolean
  languageCode: string
  languageCodes: string[]
  signature?: IHandbookSignature
  signatureRoundId?: number
  toc: BlissbookTocItem[]
  wysiwygClass: string
}

export type BlissbookState = {
  handbook: BlissbookHandbookRendered
  handbookSessionId?: number
  otherHandbooks: IHandbook[]
  featureFlagIds: string[]
  isHelpOpen: boolean
  isPrintOpen: boolean
  isSignHistoryOpen: boolean
  isSignOpen: boolean
  preferences: Record<string, any>
  [key: string]: any
}

const {
  handbook,
  organization,
  otherHandbooks,
  person,
  preferences = {},
  session,
  user,
} = window.__STATE__ as BlissbookState

export const initialState: BlissbookState = {
  ...window.__STATE__,
  handbook: Handbook.fromJSON({
    ...handbook,
    signatures: map(handbook.signatures, HandbookSignature.fromJSON),
  }),
  isHelpOpen: false,
  isLanguageOpen:
    isLive && !session.isImpersonating
      ? !preferences.defaultLanguageCode && handbook.languageCodes.length > 1
      : false,
  isPrintOpen: false,
  isSignHistoryOpen: false,
  isSignOpen: false,
  organization: Organization.fromJSON(organization),
  otherHandbooks: map(otherHandbooks, Handbook.fromJSON),
  preferences,
  person: Person.fromJSON(person),
  session: Session.fromJSON(session),
  user: User.fromJSON(user),
}
