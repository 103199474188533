export const minSearchLength = 2

export const excludeSearchWords = [
  'a',
  'an',
  'any',
  'all',
  'at',
  'as',
  'if',
  'in',
  'the',
  'for',
  'of',
  'and',
  'or',
  'is',
  'was',
  'be',
  'are',
  'to',
  'on',
  'off',
  'by',
  'can',
  'this',
  'that',
  'we',
  'you',
  'your',
  'us',
  'our',
  'his',
  'her',
  'he',
  'she',
  'it',
  'their',
  'them',
  'use',
  'used',
  '-',
]

export const isValidSearch = (search: string) =>
  search.length > minSearchLength && !excludeSearchWords.includes(search)
