import { addScrollHandler } from './util'

export function parallaxBackground(el) {
  const factor = 0.2
  const yHeight = $(el).height()

  addScrollHandler(el, (win) => {
    const yTop = $(el).offset().top - win.scrollTop
    const yBottom = yTop + yHeight
    const yBuffer = win.height * factor
    const yOffset = (win.height - (yTop + yBottom)) / 2
    $(el).css({
      'background-position-y': `calc(50% - ${yOffset * factor}px)`,
      bottom: `-${yBuffer}px`,
      top: `-${yBuffer}px`,
    })
  })
}

$.fn.parallaxBackground = function () {
  $(this).each((_index, el) => parallaxBackground(el))
}
