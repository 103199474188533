import type { ResolvedBlissbookHandbook } from '@blissbook/lib/blissbook'
import { useMouseDownOutside } from '@blissbook/ui/hooks/useMouseDownOutside'
import { Button } from '@blissbook/ui/lib'
import { useStore } from '@blissbook/ui/util/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React, { useState } from 'react'
import { useLocation } from 'react-router'
import { translate } from '../translate'
import { getLanguageUrl, getLanguages } from './util'

export const MobileLanguageMenu = (props: {
  isOpen: boolean
  setLanguageMenuOpen: (isOpen: boolean) => void
}) => {
  const handbook = useStore('handbook') as ResolvedBlissbookHandbook
  const languages = getLanguages(handbook)
  const location = useLocation()

  const { isOpen, setLanguageMenuOpen } = props
  const [node, setNode] = useState<HTMLElement | null>()

  // Close on click outside
  useMouseDownOutside(() => setLanguageMenuOpen(false), [node])

  return (
    <nav className={classnames('menu', { '-open': isOpen })} ref={setNode}>
      <header className='tw-flex lg:tw-hidden tw-items-center tw-justify-between'>
        <Button
          className='tw-flex tw-items-center btn-icon nav-close'
          color='gray-600'
          onClick={() => setLanguageMenuOpen(false)}
          title={translate('Close Menu')}
        >
          <FontAwesomeIcon icon={['far', 'bars']} />
          <FontAwesomeIcon
            icon='angle-right'
            style={{ fontSize: 12, marginLeft: 3 }}
          />
        </Button>

        <h4 className='ellipsis text-right'>Select Language</h4>
      </header>

      <div className='menu-items'>
        {languages.map((language) => (
          <a
            className={classnames('menu-item', {
              active: language.code === handbook.languageCode,
            })}
            href={getLanguageUrl(location, language.code)}
            key={language.code}
          >
            <span>{language.nativeName}</span>
          </a>
        ))}
      </div>
    </nav>
  )
}
