import $ from 'jquery'

/** Render the read-more toggle */
export function renderReadMore(
  readMoreEl: HTMLElement,
  {
    lessButtonText = 'Collapse',
    moreButtonText = 'Read More',
  }: {
    lessButtonText?: string
    moreButtonText?: string
  } = {},
) {
  // Create the toggleEl
  const toggleEl = document.createElement('div')
  toggleEl.className = 'read-more-toggle'
  readMoreEl.prepend(toggleEl)

  // Hide the content el
  const contentEl =
    readMoreEl.querySelector<HTMLDivElement>('.read-more-content')
  contentEl.style.height = '0px'

  const openButtonEl = document.createElement('button')
  openButtonEl.className = 'btn-toggle btn-toggle-open brand-primary-background'
  openButtonEl.textContent = moreButtonText
  openButtonEl.addEventListener('click', () => {
    contentEl.style.height = null
    const height = contentEl.offsetHeight
    contentEl.style.height = '0px'
    toggleEl.classList.add('animate')
    setTimeout(() => {
      toggleEl.classList.add('open')
      contentEl.style.height = height + 'px'
    })
  })
  toggleEl.append(openButtonEl)

  const closeButtonEl = document.createElement('button')
  closeButtonEl.className =
    'btn-toggle btn-toggle-close brand-primary-background'
  closeButtonEl.textContent = lessButtonText
  closeButtonEl.addEventListener('click', () => {
    const height = contentEl.offsetHeight
    contentEl.style.height = height + 'px'
    toggleEl.classList.add('animate')
    setTimeout(() => {
      toggleEl.classList.remove('open')
      contentEl.style.height = '0px'
    })
  })
  toggleEl.append(closeButtonEl)

  // After transition complete
  $(readMoreEl)
    .on(
      'transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd',
      () => {
        const isOpen = toggleEl.classList.contains('open')
        contentEl.style.height = isOpen ? null : '0px'
        toggleEl.classList.remove('animate')
      },
    )
    .on('open', () => {
      toggleEl.classList.add('open')
      contentEl.style.height = null
    })
}

// Open the read-more for this section (if any)
export function openReadMore(bookmark: string) {
  const bookmarkEl = document.querySelector(`a[name="${bookmark}"]`)
  const sectionEl = bookmarkEl?.parentElement
  if (!sectionEl) return

  const readMoreNodes = sectionEl.querySelector('.read-more')
  $(readMoreNodes).trigger('open')
}
