import { renderReadMore } from '@blissbook/ui/lib/readMore'
import $ from 'jquery'
import { initialState } from '../state'
import { isTranslation, translate } from '../translate'
const { branding } = initialState.handbook

function translateButtonText(buttonText: string, translateButtonText: string) {
  const useTranslation = isTranslation(translateButtonText)
  return useTranslation ? translate(translateButtonText) : buttonText
}

export const lessButtonText = translateButtonText(
  branding.lessButtonText,
  'Collapse',
)
export const moreButtonText = translateButtonText(
  branding.moreButtonText,
  'Read More',
)

export const readMoreEls =
  document.querySelectorAll<HTMLDivElement>('.read-more')

readMoreEls.forEach((readMoreEl) =>
  renderReadMore(readMoreEl, {
    lessButtonText,
    moreButtonText,
  }),
)

// Open the read-more for this section (if any)
export function openReadMore(bookmark: string) {
  const bookmarkEl = document.querySelector(`a[name="${bookmark}"]`)
  const sectionEl = bookmarkEl?.parentElement
  if (!sectionEl) return

  const readMoreNodes = sectionEl.querySelector('.read-more')
  $(readMoreNodes).trigger('open')
}
