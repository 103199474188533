import isString from 'lodash/isString'
import { addScrollHandler, translateX, translateY } from './util'

const positionElBy = {
  top: (el, px) => translateY(el, -px),
  bottom: (el, px) => translateY(el, px),
  left: (el, px) => translateX(el, -px),
  right: (el, px) => translateX(el, px),
}

export function slidilax(el, props) {
  const { speed = 100, lock = 30, direction = 'bottom' } = props
  const positionEl = positionElBy[direction]

  addScrollHandler(el, (win) => {
    positionEl(el, 0)
    const yTop = $(el).offset().top - win.scrollTop
    const yPercentage = (yTop * 100) / win.height

    let px = 0
    if (yPercentage > lock) {
      let ratio = (yPercentage - lock) / (100 - lock)
      if (ratio > 1) ratio = 1
      px = Math.floor(ratio * speed)
    }

    positionEl(el, px)
  })
}

// Slide elements in from a single direction into place
$.fn.slidilax = function () {
  $(this).each(function () {
    // Pull attributes
    const selector = $(this).data('slidilax')
    const speed = $(this).data('slidilax-speed')
    const lock = $(this).data('slidilax-lock')
    const direction = $(this).data('slidilax-direction')
    const props = { speed, lock, direction }

    // Apply to necessary elements
    const $els = isString(selector) ? $(this).find(selector) : $(this)
    $els.each((_index, el) => slidilax(el, props))
  })
}
