import {
  type BlissbookTocItem,
  type ResolvedAnnotation,
  formatAnnotationBookmark,
} from '@blissbook/lib/blissbook'
import { useStore } from '@blissbook/ui/util/store'
import { useMemo } from 'react'
import { initialState } from '../../state'

const { toc } = initialState.handbook

export type AnnotationsGroup = {
  annotations: ResolvedAnnotation[]
  bookmark: string
  tocItem: BlissbookTocItem
}

type FilterAnnotationsArgs = {
  isSignatureRequired: boolean
}

function getAnnotationsByBookmark(
  annotations: ResolvedAnnotation[],
  filter: FilterAnnotationsArgs,
) {
  const annotationsByBookmark = new Map<string, ResolvedAnnotation[]>()
  for (const annotation of annotations) {
    // Don't show dismissed annotations unless signature is required
    if (annotation.dismissedAt && !filter.isSignatureRequired) continue

    const bookmark = formatAnnotationBookmark(annotation)
    annotationsByBookmark.set(bookmark, [
      ...(annotationsByBookmark.get(bookmark) || []),
      annotation,
    ])
  }
  return annotationsByBookmark
}

function groupAnnotations(
  annotations: ResolvedAnnotation[],
  filter: FilterAnnotationsArgs,
) {
  const annotationGroups: AnnotationsGroup[] = []

  // Group the annotations by bookmark
  const annotationsByBookmark = getAnnotationsByBookmark(annotations, filter)

  for (const tocItem of toc) {
    const { bookmark } = tocItem
    const annotations = annotationsByBookmark.get(bookmark)
    if (!annotations) continue

    // During view, go through document annotations individually
    if (tocItem.documentId && !filter.isSignatureRequired) {
      for (const annotation of annotations) {
        annotationGroups.push({ annotations: [annotation], bookmark, tocItem })
      }
      continue
    }

    annotationGroups.push({ annotations, bookmark, tocItem })
  }

  return annotationGroups
}

export function useAnnotationGroups() {
  const annotations = useStore('handbook.annotations') as ResolvedAnnotation[]
  const isSignatureRequired = useStore('isSignatureRequired')
  return useMemo(
    () => groupAnnotations(annotations, { isSignatureRequired }),
    [annotations, isSignatureRequired],
  )
}
