import { logUIError } from '@blissbook/ui/util/integrations/sentry'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import last from 'lodash/last'
import pluralize from 'pluralize'
import React, { useState } from 'react'
import { dismissAnnotations } from '../actions'
import { initialState } from '../state'
import { isTranslation, renderTranslation, translate } from '../translate'
import { AnnotationView } from './AnnotationView'
import { useAnnotationsContext } from './AnnotationsContext'
import {
  AnnotationModal,
  AnnotationsBody,
  AnnotationsHeader,
} from './components'
import type { AnnotationsGroup, AnnotationsState } from './hooks'

const { handbook } = initialState

export function ViewAnnotationsView({ state }: { state: AnnotationsState }) {
  const { annotationGroup } = state
  if (!annotationGroup) return <IntroductionPage state={state} />
  return <AnnotationsPage annotationGroup={annotationGroup} state={state} />
}

function IntroductionPage({
  state,
}: {
  state: AnnotationsState
}) {
  const { setAnnotationGroup } = state
  const { annotationGroups } = useAnnotationsContext()
  const changeCount = annotationGroups.length
  const changeCountText = pluralize('Change', changeCount)
  const changeCountVariable = changeCount === 1 ? 'One' : '{{changeCount}}'
  return (
    <AnnotationModal id='annotation'>
      <AnnotationsHeader className='-desktop' state={state}>
        <div className='tw-text-red-700 tw-text-sm'>
          {translate(
            `${changeCountVariable} ${changeCountText} Since You Last Viewed…`,
            { changeCount: changeCount.toString() },
          )}
        </div>
      </AnnotationsHeader>

      <main className='-px -desktop'>
        <p className='tw-text-gray-600 tw-mb-0'>
          {renderTranslation(
            `To see what's changed since you last viewed the {{handbookName}}, look for a ✦ in the table of contents or use the navigation below.`,
            {
              handbookName: handbook.name,
            },
          )}
        </p>
      </main>

      <footer>
        <button
          type='button'
          className='btn btn-red'
          name='next'
          onClick={() => {
            const [annotationGroup] = annotationGroups
            setAnnotationGroup(annotationGroup)
          }}
        >
          <span className='-desktop'>{translate('Go to First Change')}</span>
          <span className='-mobile'>
            {translate(
              `${changeCountVariable} ${changeCountText} Since You Last Viewed`,
              { changeCount: changeCount.toString() },
            )}
          </span>
          <FontAwesomeIcon className='tw-ml-2' icon='arrow-right' />
        </button>
      </footer>
    </AnnotationModal>
  )
}

function AnnotationsPage({
  annotationGroup,
  state,
}: {
  annotationGroup: AnnotationsGroup
  state: AnnotationsState
}) {
  const [isSubmitting, setSubmitting] = useState(false)
  const { setAnnotationGroup, sizeClassName } = state
  const { annotationGroups } = useAnnotationsContext()
  const { annotations, tocItem } = annotationGroup
  const index = annotationGroups.indexOf(annotationGroup)
  const prevAnnotationGroup = annotationGroups[index - 1]
  const nextAnnotationGroup = annotationGroups[index + 1]

  async function dismissAnnotationGroup(annotationGroup: AnnotationsGroup) {
    setSubmitting(true)

    try {
      // Mark the section as read
      const { annotations } = annotationGroup
      const annotationIds = annotations.map((a) => a.id)
      await dismissAnnotations(annotationIds)

      // Go to the next group
      const index = annotationGroups.indexOf(annotationGroup)
      const nextAnnotationGroup =
        annotationGroups[index + 1] || annotationGroups[index - 1]
      setAnnotationGroup(nextAnnotationGroup)
    } catch (error: any) {
      logUIError(error)
    }

    setSubmitting(false)
  }

  return (
    <AnnotationModal id='annotation' className={sizeClassName}>
      <AnnotationsHeader state={state}>
        <div className='tw-text-red-700 tw-text-sm'>{tocItem.title}</div>
      </AnnotationsHeader>

      <AnnotationsBody state={state}>
        <div className='tw-flex tw-flex-col-reverse -px'>
          {annotations.map((annotation) => (
            <AnnotationView
              annotation={annotation}
              className='tw-mb-2'
              key={annotation.id}
            />
          ))}
        </div>
      </AnnotationsBody>

      <footer>
        <div className='tw-flex-1 tw-flex tw-items-center'>
          <button
            type='button'
            className='btn btn-secondary'
            disabled={isSubmitting}
            onClick={() => {
              setAnnotationGroup(prevAnnotationGroup)
            }}
          >
            <FontAwesomeIcon icon='arrow-left' />
          </button>

          <If condition={annotationGroup !== last(annotationGroups)}>
            <button
              type='button'
              className='btn btn-secondary'
              disabled={isSubmitting}
              onClick={() => {
                setAnnotationGroup(nextAnnotationGroup)
              }}
            >
              <FontAwesomeIcon icon='arrow-right' />
            </button>
          </If>
        </div>

        <button
          type='button'
          className='btn btn-red'
          disabled={isSubmitting}
          name='dismiss'
          onClick={() => dismissAnnotationGroup(annotationGroup)}
        >
          {isTranslation('Dismiss') ? translate('Dismiss') : 'Got It, Dismiss'}
        </button>
      </footer>
    </AnnotationModal>
  )
}
