import { Button } from '@blissbook/ui/lib'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React from 'react'
import type { MouseEvent } from 'react'
import type { SearchState } from './state'

type SearchIconButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  color?: string
  icon: IconProp
}

export const SearchIconButton: React.FC<SearchIconButtonProps> = ({
  className,
  color = 'gray-600',
  icon,
  ...props
}) => (
  <Button
    {...props}
    className={classnames('btn-icon btn-hover-info tw-px-2', className)}
    color={color}
  >
    <FontAwesomeIcon icon={icon} />
  </Button>
)

const SearchResultText: React.FC = (props) => (
  <div
    {...props}
    className='tw-text-gray-600 tw-mx-2'
    onMouseDown={onMouseDown}
    style={{ fontSize: 13 }}
  />
)

const onMouseDown = (event: MouseEvent) => {
  event.preventDefault()
}

export const SearchResultInput: React.FC<{
  noResults?: string
  state: SearchState
}> = ({ noResults, state }) => {
  const { moveResultIndex, onChangeSearch, resultIndex, results, search } =
    state
  return (
    <Choose>
      <When condition={!search} />
      <When condition={results.length > 0}>
        <SearchResultText>
          {resultIndex + 1} / {results.length}
        </SearchResultText>

        <SearchIconButton
          className='tw-ml-1'
          icon='times-circle'
          onClick={() => {
            onChangeSearch(null)
          }}
          onMouseDown={onMouseDown}
          style={{ fontSize: 14 }}
        />

        <SearchIconButton
          className='tw-ml-1'
          disabled={resultIndex >= results.length - 1}
          icon={['far', 'angle-down']}
          onClick={() => {
            moveResultIndex(1)
          }}
          onMouseDown={onMouseDown}
          style={{ fontSize: 18 }}
        />

        <SearchIconButton
          className='tw-ml-1'
          disabled={resultIndex <= 0}
          icon={['far', 'angle-up']}
          onClick={() => {
            moveResultIndex(-1)
          }}
          onMouseDown={onMouseDown}
          style={{ fontSize: 18 }}
        />
      </When>
      <When condition={!!noResults}>
        <SearchResultText>{noResults}</SearchResultText>

        <SearchIconButton
          className='tw-ml-1'
          icon='times-circle'
          onClick={() => {
            onChangeSearch(null)
          }}
          onMouseDown={onMouseDown}
          style={{ fontSize: 14 }}
        />
      </When>
    </Choose>
  )
}
