import type { ResolvedBlissbookHandbook } from '@blissbook/lib/blissbook'
// @ts-ignore: WIP imports
import { ScrollContainer, useScrollLock } from '@blissbook/ui/lib/scroll'
import { handleError } from '@blissbook/ui/util/errors'
import { useStore } from '@blissbook/ui/util/store'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import React, { useState } from 'react'
import { setDefaultLanguageCode } from '../actions'
import { getTranslator } from '../translate'
import { getLanguages } from './util'

export function LanguageSelector() {
  const handbook = useStore('handbook') as ResolvedBlissbookHandbook
  const person = useStore('person')

  const [languageCode, setLanguageCode] = useState(handbook.defaultLanguageCode)
  const languages = getLanguages(handbook)
  const language = languages.find((l) => l.code === languageCode)
  const translator = getTranslator(languageCode)

  useScrollLock()

  return (
    <div className='modal tw-flex tw-items-center tw-justify-center'>
      <div className='modal-dialog tw-p-12 tw-rounded-lg'>
        <div className='tw-mb-6'>
          <p className='tw-text-3xl'>{translator.translate('Hello,')}</p>

          {person && <p className='tw-text-4xl'>{person.firstName}</p>}
        </div>

        <p className='tw-text-xl'>
          {translator.translate('Choose your language:')}
        </p>

        <Formik
          initialValues={{}}
          onSubmit={async (_values, { setStatus }) => {
            setStatus({ isSubmitting: true })

            try {
              await setDefaultLanguageCode(languageCode)
            } catch (error) {
              handleError(error)
              setStatus()
            }
          }}
        >
          {({ handleSubmit, status: { isSubmitting } = {} }) => (
            <form noValidate onSubmit={handleSubmit}>
              <fieldset disabled={isSubmitting}>
                <ScrollContainer
                  className='tw-my-4'
                  innerClassName='tw-flex tw-flex-col tw-gap-4 tw-pb-2'
                  maxHeight={275}
                >
                  {languages.map((language, index) => {
                    const active = language.code === languageCode
                    return (
                      <button
                        className={cx(
                          'tw-border tw-border-gray-200 tw-border-solid tw-flex tw-items-center tw-justify-between tw-group tw-px-4 tw-py-2 tw-rounded-lg',
                          active
                            ? 'tw-shadow-md'
                            : 'hover:tw-cursor-pointer hover:tw-border-gray-400 hover:tw-shadow-md',
                        )}
                        data-language-code={language.code}
                        key={index}
                        onClick={() => {
                          setLanguageCode(language.code)
                        }}
                        type='button'
                      >
                        <div>
                          <div
                            className={cx(
                              active
                                ? 'tw-text-gray-700'
                                : 'tw-text-gray-500 group-hover:tw-text-gray-700',
                            )}
                          >
                            {language.nativeName}
                          </div>

                          <div
                            className={cx(
                              'tw-text-xs',
                              active
                                ? 'tw-text-gray-500'
                                : 'tw-text-gray-400 group-hover:tw-text-gray-500',
                            )}
                          >
                            {language.isoName}
                          </div>
                        </div>

                        <FontAwesomeIcon
                          className={cx(
                            'tw-text-green tw-text-2xl tw-text-transparent',
                            active
                              ? 'tw-text-green-700'
                              : 'group-hover:tw-text-gray-300',
                          )}
                          icon={['fad', 'circle-check']}
                        />
                      </button>
                    )
                  })}
                </ScrollContainer>

                <button
                  className='btn btn-dark tw-block tw-mx-auto tw-mt-8'
                  type='submit'
                >
                  {translator.translate('View the {{nativeName}} version', {
                    nativeName: language.nativeName.toLowerCase(),
                  })}
                </button>
              </fieldset>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}
