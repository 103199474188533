import './AnnotationsView.scss'
import { useStore } from '@blissbook/ui/util/store'
import React from 'react'
import { useAnnotationsContext } from './AnnotationsContext'
import { SignAnnotationsView } from './SignAnnotationsView'
import { ViewAnnotationsView } from './ViewAnnotationsView'
import { useAnnotationsState } from './hooks'

export function AnnotationsView() {
  const { annotations } = useAnnotationsContext()
  const state = useAnnotationsState()
  const isSignatureRequired = useStore('isSignatureRequired')
  const hasAnnotations = annotations.length > 0
  const Component = isSignatureRequired
    ? SignAnnotationsView
    : ViewAnnotationsView
  if (!hasAnnotations) return null
  return <Component state={state} />
}
