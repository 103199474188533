import { mergeRefs } from '@blissbook/ui/util'
import classnames from 'classnames'
import React from 'react'
import { forwardRef, useEffect, useState } from 'react'
import { initialState } from './state'
const { wysiwygClass } = initialState.handbook

type TemplateWysiwygProps = React.HTMLAttributes<HTMLDivElement> & {
  templateId: string
}

export const TemplateWysiwyg = forwardRef<HTMLDivElement, TemplateWysiwygProps>(
  ({ className, templateId, ...props }, ref) => {
    const [, setNode] = useTemplateNode(templateId)
    return (
      <div
        {...props}
        className={classnames(wysiwygClass, className)}
        ref={mergeRefs([ref, setNode])}
      />
    )
  },
)

const cloneTemplateById = (node: HTMLElement, templateId: string) => {
  const templateNode = document.getElementById(
    templateId,
  ) as HTMLTemplateElement
  if (!templateNode) return

  const clone = templateNode.content.cloneNode(true)
  node.innerHTML = ''
  node.appendChild(clone)
}

export const useTemplateNode = (templateId: string) => {
  const [node, setNode] = useState<HTMLElement | null>()

  useEffect(() => {
    if (!node) return

    cloneTemplateById(node, templateId)
  }, [node, templateId])

  return [node, setNode] as const
}
