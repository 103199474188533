import throttle from 'lodash/throttle'

// List of scroll handlers
const scrollHandlers = []

// Get the window properties`
const getWindowProps = () => ({
  height: $(window).height(),
  scrollTop: $(window).scrollTop(),
})

// Handle scroll
const runScrollHandlers = (handlers) => {
  const props = getWindowProps()
  handlers.forEach((handler) => handler(props))
}

// Add a scroll handler, run after create
export function addScrollHandler(_el, handler) {
  const props = getWindowProps()
  handler(props)
  scrollHandlers.push(handler)
}

// Throttle scroll handler
const onScroll = () => runScrollHandlers(scrollHandlers)
const onScrollThrottled = throttle(onScroll, 10)
$(window).resize(onScrollThrottled)
$(window).scroll(onScrollThrottled)

export const transform = (el, transform) => {
  $(el).css({
    transform: transform,
    '-ms-transform': transform,
    '-webkit-transform': transform,
  })
}

export const translateX = (el, px) => {
  transform(el, `translateX(${px}px)`)
}

export const translateY = (el, px) => {
  transform(el, `translateY(${px}px)`)
}
