import type { ResolvedBlissbookHandbook } from '@blissbook/lib/blissbook'
import { Button } from '@blissbook/ui/lib'
import { useStore } from '@blissbook/ui/util/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { getLanguage, getOtherLanguages } from '../language'
import { translate } from '../translate'

export const MobileNav = ({
  isOpen,
  setLanguageMenuOpen,
  setMenuOpen,
  setTocOpen,
}: {
  isOpen: boolean
  setLanguageMenuOpen: (isOpen: boolean) => void
  setMenuOpen: (isOpen: boolean) => void
  setTocOpen: (isOpen: boolean) => void
}) => {
  const handbook = useStore('handbook') as ResolvedBlissbookHandbook
  const language = getLanguage(handbook)
  const otherLanguages = getOtherLanguages(handbook)

  return (
    <nav
      className='tw-flex lg:tw-hidden tw-items-center tw-justify-between mobile'
      css={{
        opacity: isOpen ? 1 : 0,
        pointerEvents: isOpen ? undefined : 'none',
        maxWidth: '100%',
      }}
    >
      <Button className='btn-link' onClick={() => setTocOpen(true)}>
        <FontAwesomeIcon
          className='tw-mr-4'
          icon='bars'
          style={{ fontSize: 20 }}
        />{' '}
        {translate('TOC')}
      </Button>

      {otherLanguages.length > 0 && (
        <Button
          className='tw-flex tw-items-center btn-link'
          onClick={() => setLanguageMenuOpen(true)}
        >
          <FontAwesomeIcon
            className='tw-mr-1'
            icon={['far', 'globe']}
            style={{ fontSize: 16 }}
          />
          {language ? language.nativeName : 'Unknown'}
          <FontAwesomeIcon className='tw-ml-1' icon={['fas', 'chevron-down']} />
        </Button>
      )}

      <Button className='btn-link' onClick={() => setMenuOpen(true)}>
        {translate('Menu')}{' '}
        <FontAwesomeIcon
          className='tw-ml-4'
          icon='ellipsis-v'
          style={{ fontSize: 20 }}
        />
      </Button>
    </nav>
  )
}
