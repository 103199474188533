import { gql, useQuery } from '@apollo/client'
import { Group } from '@blissbook/common/group'
import Handbook from '@blissbook/common/handbook'
import { Person } from '@blissbook/common/person'
import { client } from '@blissbook/ui/util/apollo'
import { useMemo } from 'react'

export const usePreviewQuery = (handbookId: number) => {
  const { data, ...query } = useQuery(
    gql`
      query getPreview($handbookId: Int!) {
        handbook(id: $handbookId) {
          id
          public
          hasAudience
        }

        groups {
          id
          name
          peopleCount
        }
      }
    `,
    {
      variables: { handbookId },
    },
  )

  const groups = useMemo(() => data?.groups.map(Group.fromJSON), [data])

  const handbook = useMemo(
    () => data && Handbook.fromJSON(data.handbook),
    [data],
  )

  return { ...query, groups, handbook }
}

export const getPeople = async (variables: object) => {
  const { data } = await client.query({
    fetchPolicy: 'no-cache',
    query: gql`
      query getPeople(
        $after: String
        $filter: PeopleFilter
        $limit: Int
        $order: PeopleSortOrder
        $search: String
      ) {
        people(
          after: $after
          filter: $filter
          limit: $limit
          order: $order
          search: $search
        ) {
          id
          email
          fullName
          groupIds
          sortKey
        }
      }
    `,
    variables,
  })

  return data.people.map(Person.fromJSON)
}
