import { immerable } from 'immer'
import Organization from './organization'
import { fromJSON, mapJSONValues } from './util/json'

export class User {
  authType: string
  authId: string

  static get ATTRIBUTES() {
    return {
      createdAt: fromJSON.date,
      lastSignedInAt: fromJSON.date,
      organization: Organization.fromJSON,
      resetSessionAt: fromJSON.date,
    }
  }

  // Map attributes
  static mapJSON(json: any) {
    return mapJSONValues(json, User.ATTRIBUTES)
  }

  // Create a user from JSON
  static fromJSON(json: any) {
    return json && Object.assign(new User(), User.mapJSON(json))
  }
}

// @ts-ignore: immerable
User[immerable] = true
