import { Modal } from '@blissbook/ui/lib'
import React from 'react'
import { TemplateWysiwyg, useTemplateNode } from './wysiwyg'

export const ContactModal = Modal.wrap((props) => {
  const [, setTitleNode] = useTemplateNode('contact-title')
  return (
    <Modal.Component
      {...props}
      position='center'
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      width={600}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title ref={setTitleNode} />
        </Modal.Header>

        <Modal.Body>
          <TemplateWysiwyg className='body' templateId='contact-body' />
        </Modal.Body>
      </Modal.Content>
    </Modal.Component>
  )
})
