import isString from 'lodash/isString'
import { addScrollHandler, translateY } from './util'

export function parallax(el, props) {
  const factor = props.factor || 1
  const lockPercentage = props.lock || 0
  const lockFactor = lockPercentage / 100
  const yHeight = $(el).height()

  let yOffset = 0
  addScrollHandler(el, (win) => {
    const yTopBase = $(el).offset().top - yOffset
    const yTop = yTopBase - win.scrollTop
    const yBottom = yTop + yHeight
    const yLockTop = win.height * (0.5 - lockFactor / 2)
    const yLockBottom = win.height * (0.5 + lockFactor / 2)

    yOffset = 0
    if (yBottom < yLockTop) yOffset = (yBottom - yLockTop) * factor
    if (yTop > yLockBottom) yOffset = (yTop - yLockBottom) * factor
    translateY(el, yOffset)
  })
}

// Move elements up and down at a different scroll speed than the page
$.fn.parallax = function () {
  $(this).each(function () {
    // Pull attributes
    const selector = $(this).data('parallax')
    const speed = $(this).data('parallax-speed')
    const lock = $(this).data('parallax-lock')
    const props = { speed, lock }

    // Apply to necessary elements
    const $els = isString(selector) ? $(this).find(selector) : $(this)
    $els.each((_index, el) => parallax(el, props))
  })
}
