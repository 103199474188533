import { useSession } from '@blissbook/application/hooks'
import type { ResolvedBlissbookHandbook } from '@blissbook/lib/blissbook'
import type { Language } from '@blissbook/lib/languages'
import { colors } from '@blissbook/ui/branding'
import { Button, Dropdown } from '@blissbook/ui/lib'
import { useStore } from '@blissbook/ui/util/store'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useLocation } from 'react-router'
import { setDefaultLanguageCode } from '../actions'
import { isLive } from '../state'
import {
  getLanguage,
  getLanguageUrl,
  getLanguages,
  getOtherLanguages,
} from './util'

const PreviewLanguageDropdown: React.FC<{
  className?: string
}> = ({ className, ...props }) => {
  const location = useLocation()
  const handbook = useStore('handbook') as ResolvedBlissbookHandbook
  const language = getLanguage(handbook)
  const otherLanguages = getOtherLanguages(handbook)
  return (
    <Dropdown.Provider>
      <Dropdown.ToggleButton
        {...props}
        className={cx(
          'tw-flex tw-items-center btn-icon btn-gray-500 tw-pl-2 tw-pr-1 tw-py-2',
          className,
        )}
        style={{
          borderLeft: `1px solid ${colors['gray-400']}`,
          fontSize: 12,
          lineHeight: 1,
        }}
      >
        <FontAwesomeIcon className='tw-mr-1' icon={['far', 'globe']} />
        {language ? language.nativeName : 'Unknown'}
      </Dropdown.ToggleButton>

      <Dropdown.Menu>
        {otherLanguages.map((language) => (
          <a
            className='dropdown-item'
            href={getLanguageUrl(location, language.code)}
            key={language.code}
          >
            {language.nativeName}
          </a>
        ))}
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
}

const LiveLanguageDropdown: React.FC<{
  className?: string
}> = ({ className, ...props }) => {
  const handbook = useStore('handbook') as ResolvedBlissbookHandbook
  const language = getLanguage(handbook)
  const languages = getLanguages(handbook)

  const isDefaultLanguage = (language: Language): boolean => {
    return (
      handbook.languageCodes.length && handbook.languageCode === language.code
    )
  }

  const updateDefaultLanguageCode = async (languageCode: string) => {
    await setDefaultLanguageCode(languageCode)
  }

  return (
    <Dropdown.Provider>
      <Dropdown.ToggleButton
        {...props}
        className={cx(
          'tw-flex tw-items-center btn-icon btn-gray-500 tw-pl-2 tw-pr-1 tw-py-2',
          className,
        )}
        style={{
          borderLeft: `1px solid ${colors['gray-400']}`,
          fontSize: 12,
          lineHeight: 1,
        }}
      >
        <FontAwesomeIcon className='tw-mr-1' icon={['far', 'globe']} />
        {language ? language.nativeName : 'Unknown'}
      </Dropdown.ToggleButton>

      <Dropdown.Menu style={{ minWidth: '320px' }}>
        {languages.map((language) => (
          <div
            className='dropdown-item tw-color-black tw-group'
            key={language.code}
          >
            <If condition={isDefaultLanguage(language)}>
              <div className='tw-text-black tw-no-underline tw-flex tw-items-center tw-justify-between'>
                {language.nativeName}
                <span className='badge'>default</span>
              </div>
            </If>

            <If condition={!isDefaultLanguage(language)}>
              <div>
                <Button
                  className='tw-w-full tw-flex tw-justify-between tw-items-center'
                  onClick={() => updateDefaultLanguageCode(language.code)}
                >
                  {language.nativeName}
                  <div className='badge tw-bg-white tw-border-none tw-invisible tw-text-gray-500 group-hover:tw-visible'>
                    Set as default
                  </div>
                </Button>
              </div>
            </If>
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
}

export const DesktopLanguageDropdown = () => {
  const session = useSession()
  return !session.isImpersonating && isLive ? (
    <LiveLanguageDropdown />
  ) : (
    <PreviewLanguageDropdown />
  )
}
