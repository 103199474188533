import type { ResolvedBlissbookHandbook } from '@blissbook/lib/blissbook'
import { findLanguageByCode } from '@blissbook/lib/languages'
import type { Location } from 'history'
import qs from 'query-string'

export const getLanguageUrl = (location: Location, languageCode: string) => {
  const { hash, pathname, search } = location
  const query = qs.parse(search)
  query.language = languageCode
  return `${pathname}?${qs.stringify(query)}${hash}`
}

export const getLanguage = (handbook: ResolvedBlissbookHandbook) => {
  const { languageCode } = handbook
  return findLanguageByCode(languageCode)
}

export const getLanguages = (handbook: ResolvedBlissbookHandbook) => {
  const { languageCodes } = handbook
  return languageCodes.map(findLanguageByCode)
}

export const getOtherLanguages = (handbook: ResolvedBlissbookHandbook) => {
  const { languageCode, languageCodes } = handbook
  return languageCodes
    .filter((lc) => lc !== languageCode)
    .map(findLanguageByCode)
}
