import type { Location } from 'history'
import queryString from 'query-string'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { highlightSearchResults, setActiveSearchResult } from './highlight'
import { type SearchResult, getResults } from './results'

export type SearchState = {
  moveResultIndex: (delta: number) => void
  onChangeSearch: (search: string) => void
  resultIndex: number
  result: SearchResult
  results: SearchResult[]
  search: string
  setResultIndex: (index: number) => void
}

// Helper to get the initial search
const getInitialSearch = (location: Location) => {
  const query = queryString.parse(location.search)
  return query.search || null
}

// Get the sectionId from the hash
const parseSectionId = (hash: string) => {
  const match = hash.match(/section[-_](\d+)/)
  if (match) return Number.parseInt(match[1], 10)
}

// Helper to get the initial result index
const getInitialResultIndex = (location: Location, results: SearchResult[]) => {
  // If no initial section, just start at the beginning
  const sectionId = parseSectionId(location.hash)
  if (!sectionId) return 0

  // Try to find a result with this sectionId
  const result = results.find(
    (result) => result.tocItem.sectionId === sectionId,
  )
  return result ? results.indexOf(result) : 0
}

export const useSearchState = () => {
  const location = useLocation()

  // Search State
  const [search, setSearch] = useState<string>(() => getInitialSearch(location))

  // Calculate results from search
  const results = useMemo(() => getResults(search), [search])

  // Result Index
  const [resultIndex, setResultIndex] = useState(() =>
    getInitialResultIndex(location, results),
  )
  const result = results[resultIndex]

  // Search change handler
  const onChangeSearch = useCallback((search: string) => {
    setSearch(search)
    setResultIndex(0)
  }, [])

  // Helper to move the result index
  const moveResultIndex = (delta: number) => {
    let index = resultIndex + delta
    index = Math.max(index, 0)
    index = Math.min(index, results.length - 1)
    setResultIndex(index)
  }

  // Highlight search results
  useEffect(() => {
    highlightSearchResults(search, results)
  }, [search, results])

  // Set active search result
  useEffect(() => {
    setActiveSearchResult(result)
  }, [result])

  return {
    moveResultIndex,
    onChangeSearch,
    resultIndex,
    result,
    results,
    search,
    setResultIndex,
  } as SearchState
}
