import { VideoPlayer } from '@blissbook/ui/lib/video'
import React from 'react'
import ReactDOM from 'react-dom'

const videoEls = document.getElementsByTagName('rw-video')
for (const videoEl of videoEls) {
  const props = {
    url: videoEl.getAttribute('src'),
    width: Number.parseInt(videoEl.style.width, 10),
    height: Number.parseInt(videoEl.style.height, 10),
  }

  ReactDOM.render(<VideoPlayer {...props} />, videoEl)
}
