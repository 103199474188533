import { Button } from '@blissbook/ui/lib'
import { useStore } from '@blissbook/ui/util/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React, { useRef, useState } from 'react'
import { Transition } from 'react-transition-group'
import { setSignOpen } from '../actions'
import { renderTranslation, translate } from '../translate'
import { AnnotationView } from './AnnotationView'
import { useAnnotationsContext } from './AnnotationsContext'
import {
  AnnotationModal,
  AnnotationsBody,
  AnnotationsHeader,
} from './components'
import type { AnnotationsGroup, AnnotationsState } from './hooks'

export function SignAnnotationsView({ state }: { state: AnnotationsState }) {
  const [showIntroduction, setShowIntroduction] = useState(true)

  if (showIntroduction) {
    return (
      <IntroductionPage
        onClose={() => setShowIntroduction(false)}
        state={state}
      />
    )
  }

  return <AnnotationsPage state={state} />
}
const IntroductionPage = ({
  onClose,
  state,
}: {
  onClose: () => void
  state: AnnotationsState
}) => {
  const signatures = useStore('handbook.signatures')
  return (
    <AnnotationModal id='annotation'>
      <AnnotationsHeader className='-desktop' state={state}>
        <div className='tw-text-red-700 tw-text-sm'>
          {translate(
            `Since You Last ${signatures.length ? 'Signed' : 'Viewed'}`,
          )}
          …
        </div>
      </AnnotationsHeader>

      <main className='-px -desktop'>
        <p className='tw-text-gray-600'>
          {renderTranslation(
            `To see what's changed since you last ${
              signatures.length ? 'signed' : 'viewed'
            }, look for a ✦ in the table of contents or use the review menu below.`,
          )}
        </p>
        <p className='tw-text-gray-600 disclaimer tw-mb-0'>
          {translate(
            'Note: There may be changes not presented here that were deemed unnecessary to highlight.',
          )}
        </p>
      </main>

      <footer>
        <button
          type='button'
          className='btn btn-secondary tw-flex tw-items-center tw-justify-center text-left'
          onClick={onClose}
          style={{ flex: 2 }}
        >
          <FontAwesomeIcon className='tw-mr-2' icon='bars' />
          {translate('Review Changes')}
        </button>

        <button
          type='button'
          className='btn btn-red'
          onClick={() => setSignOpen(true)}
          style={{ flex: 1 }}
        >
          {translate('Sign')}
        </button>
      </footer>
    </AnnotationModal>
  )
}

const AnnotationsPage = ({
  state,
}: {
  state: AnnotationsState
}) => {
  const { annotationGroup, setAnnotationGroup, sizeClassName } = state
  const { annotationGroups } = useAnnotationsContext()
  const annotationGroupRef = useRef(annotationGroup)
  if (annotationGroup) annotationGroupRef.current = annotationGroup
  const currentAnnotationGroup = annotationGroupRef.current

  return (
    <div id='annotation' className={sizeClassName}>
      <AnnotationPage in={!annotationGroup} className='exit-left'>
        <AnnotationModal>
          <AnnotationsHeader className='tw-text-red-700' state={state}>
            <h5>{translate('Sections That Changed:')}</h5>
          </AnnotationsHeader>

          <AnnotationsBody state={state}>
            {annotationGroups.map((annotationGroup) => {
              const { annotations, tocItem } = annotationGroup
              return (
                <button
                  className='annotation-section tw-text-black'
                  key={tocItem.bookmark}
                  onClick={() => setAnnotationGroup(annotationGroup)}
                  type='button'
                >
                  <span className='tw-mr-2' />
                  <span className='tw-flex-1'>
                    {tocItem.title}{' '}
                    {annotations.length > 1 && (
                      <span className='tw-ml-1 tw-text-gray-500'>
                        {annotations.length}
                      </span>
                    )}
                  </span>

                  <FontAwesomeIcon
                    className='tw-ml-2 tw-text-gray-500'
                    icon='angle-right'
                  />
                </button>
              )
            })}
          </AnnotationsBody>

          <footer>
            <button
              type='button'
              className='btn btn-red btn-block'
              onClick={() => setSignOpen(true)}
            >
              <FontAwesomeIcon className='tw-mr-2' icon='check' />
              {translate('Sign')}
            </button>
          </footer>
        </AnnotationModal>
      </AnnotationPage>

      <AnnotationPage in={!!annotationGroup} className='exit-right'>
        {currentAnnotationGroup && (
          <SignAnnotationModal
            annotationGroup={currentAnnotationGroup}
            state={state}
          />
        )}
      </AnnotationPage>
    </div>
  )
}

const AnnotationPage = ({
  children,
  className,
  ...props
}: {
  children?: React.ReactNode
  className?: string
  in: boolean
}) => (
  <Transition {...props} timeout={250}>
    {(state: string) => {
      return (
        <div className={classnames('annotation-page', className, state)}>
          {children}
        </div>
      )
    }}
  </Transition>
)

function SignAnnotationModal({
  annotationGroup,
  state,
}: {
  annotationGroup: AnnotationsGroup
  state: AnnotationsState
}) {
  const { annotationId, setAnnotationGroup, setAnnotationId } = state
  const { annotations, tocItem } = annotationGroup
  const annotation = annotations.find((a) => a.id === annotationId)
  const canNavigate = annotations.length > 1 && !!tocItem.documentId
  const index = annotations.indexOf(annotation)
  const prevAnnotation = annotations[index - 1]
  const nextAnnotation = annotations[index + 1]

  // Keep track of the last one to survive closes
  const activeAnnotationRef = useRef(annotation)
  if (annotation) activeAnnotationRef.current = annotation
  const activeAnnotation = activeAnnotationRef.current

  return (
    <AnnotationModal className='tw-flex tw-flex-row'>
      <button
        type='button'
        className='btn btn-secondary tw-rounded-r-none'
        onClick={() => setAnnotationGroup(undefined)}
      >
        <FontAwesomeIcon icon='arrow-left' />
      </button>

      <div className='tw-flex-1 tw-flex tw-flex-col'>
        <AnnotationsHeader state={state}>
          <div className='tw-text-red-700 tw-text-sm'>
            {tocItem.title ?? '(no title)'}
          </div>
        </AnnotationsHeader>

        <AnnotationsBody state={state}>
          <div className='tw-flex tw-flex-col-reverse -px'>
            <Choose>
              {/** If we're not in a document, show all annotations */}
              <When condition={canNavigate}>
                <AnnotationView
                  annotation={activeAnnotation}
                  className='tw-mb-2'
                />
              </When>
              <Otherwise>
                {annotations.map((annotation) => (
                  <AnnotationView
                    annotation={annotation}
                    className='tw-mb-2'
                    key={annotation.id}
                  />
                ))}
              </Otherwise>
            </Choose>
          </div>
        </AnnotationsBody>

        {canNavigate && (
          <div className='tw-flex tw-items-center tw-gap-2 tw-p-2'>
            {prevAnnotation && (
              <Button
                className='tw-flex-1'
                color='secondary'
                onClick={() => {
                  setAnnotationId(prevAnnotation.id)
                }}
                outline
              >
                <FontAwesomeIcon className='tw-mr-2' icon='arrow-left' />
                Prev
              </Button>
            )}
            {nextAnnotation && (
              <Button
                className='tw-flex-1'
                color='secondary'
                onClick={() => {
                  setAnnotationId(nextAnnotation.id)
                }}
                outline
              >
                Next
                <FontAwesomeIcon className='tw-ml-2' icon='arrow-right' />
              </Button>
            )}
          </div>
        )}
      </div>
    </AnnotationModal>
  )
}
