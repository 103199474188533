import type { ResolvedAnnotation } from '@blissbook/lib/blissbook'
import { createContext, useContext, useMemo } from 'react'
import { type AnnotationsGroup, useAnnotationGroups } from './hooks'

export type AnnotationsContextProps = {
  annotations: ResolvedAnnotation[]
  annotationGroups: AnnotationsGroup[]
  annotationGroupsByAnnotationId: Map<string, AnnotationsGroup>
  annotationGroupsByBookmark: Map<string, AnnotationsGroup>
}

const AnnotationsContext = createContext<AnnotationsContextProps>(undefined)

export function AnnotationsProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const annotationGroups = useAnnotationGroups()

  const annotations = useMemo(
    () => annotationGroups.flatMap((group) => group.annotations),
    [annotationGroups],
  )

  const annotationGroupsByAnnotationId = useMemo(
    () =>
      new Map(
        annotationGroups.flatMap((annotationGroup) =>
          annotationGroup.annotations.map((annotation) => [
            annotation.id,
            annotationGroup,
          ]),
        ),
      ),
    [annotationGroups],
  )

  const annotationGroupsByBookmark = useMemo(
    () =>
      new Map(
        annotationGroups.map((annotationGroup) => [
          annotationGroup.tocItem.bookmark,
          annotationGroup,
        ]),
      ),
    [annotationGroups],
  )

  return (
    <AnnotationsContext.Provider
      value={{
        annotations,
        annotationGroups,
        annotationGroupsByAnnotationId,
        annotationGroupsByBookmark,
      }}
    >
      {children}
    </AnnotationsContext.Provider>
  )
}

export const useAnnotationsContext = () => useContext(AnnotationsContext)
