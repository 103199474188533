import uniq from 'lodash/uniq'
import { SECTION_SELECTOR, type SearchResult } from './results'

const $blissbookEl = $(SECTION_SELECTOR)

// Highlight search results
export const highlightSearchResults = (
  search: string,
  results: SearchResult[],
) => {
  // Highlight results
  $blissbookEl.unhighlight()
  if (results.length) $blissbookEl.highlight(search)

  // Ensure the legal content is open
  const resultEls = uniq(results.map((r) => r.el))
  resultEls.forEach((el) => {
    $(el).parents('.read-more').trigger('open')
  })
}

// Highlight active result
export const setActiveSearchResult = (result: SearchResult) => {
  // Remove any existing highlights
  $blissbookEl.find('.highlight').removeClass('active')

  // If no result, stop
  if (!result) return

  // Highlight the result in the handbook
  const highlightEl = $(result.el).find('.highlight')[result.instance]
  $(highlightEl).addClass('active')

  // Scroll to the text
  const offset = $(result.el).offset()
  const height = $(window).height()
  const top = offset.top - height / 2
  $('html,body').scrollTop(top)
}
