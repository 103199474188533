import { colors } from '@blissbook/ui/branding'
import moment from 'moment'
import React, { type CSSProperties } from 'react'

export const SignatureRecordedView = ({
  person,
  signature,
  ...props
}: {
  person: IPerson
  signature: IHandbookSignature
}) => (
  <div {...props}>
    {person.fullName}'s signature was manually recorded by{' '}
    {signature.recordedByPerson.fullName} on{' '}
    {moment(signature.createdAt).format('MMMM D, YYYY')} at{' '}
    {moment(signature.createdAt).format('h:mma')}. It was not obtained from the
    signee in Blissbook.
  </div>
)

export const SignatureRecordedPrintAsterisk = ({
  style,
  ...props
}: {
  style?: CSSProperties
}) => (
  <span
    {...props}
    style={{
      ...style,
      color: colors['red-500'],
    }}
  >
    *
  </span>
)

export const SignatureRecordedPrintView = ({
  person,
  signature,
  style,
  ...props
}: {
  person: IPerson
  signature: IHandbookSignature
  style?: CSSProperties
}) => (
  <div
    {...props}
    style={{
      ...style,
      alignItems: 'center',
      backgroundColor: colors['gray-100'],
      borderRadius: 4,
      display: 'flex',
      padding: '12pt 12pt',
    }}
  >
    <SignatureRecordedPrintAsterisk
      style={{
        fontSize: '24pt',
        marginRight: '8pt',
        transform: 'translateY(4px)',
      }}
    />

    <SignatureRecordedView person={person} signature={signature} />
  </div>
)
