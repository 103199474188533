import { useEffect, useRef } from 'react'

const events = ['mousemove', 'mousedown', 'keypress', 'touchmove']

export const addInactivityListener = (listener, wait) => {
  // Reset the timer
  let timerId
  const resetTimer = () => {
    if (timerId) clearTimeout(timerId)
    timerId = setTimeout(listener, wait)
  }

  // Start the events
  events.forEach((event) => document.addEventListener(event, resetTimer, false))
  resetTimer()

  // Return callback to disconnect
  return () => {
    events.forEach((event) =>
      document.removeEventListener(event, resetTimer, false),
    )
    clearTimeout(timerId)
  }
}

// Hook to callback on inactivity
export const useInactivity = (listener, wait) => {
  const listenerRef = useRef()

  // Ref to keep track of current listener
  useEffect(() => {
    listenerRef.current = listener
  }, [listener])

  // Manage the inactivity listener
  useEffect(
    () => addInactivityListener(() => listenerRef.current(), wait),
    [wait],
  )
}
