import { setPreferences } from '@blissbook/ui/application/api/preferences'
import { patchQuery } from '@blissbook/ui/util/history'
import * as api from './queries'
import { initialState, isLive } from './state'
import { store } from './store'
const { handbook } = initialState
const { acknowledgementForm } = handbook

// UI -------------------------------------------------------------------------

export const setHelpOpen = (isOpen: boolean) => {
  store.produce((draft) => {
    draft.isHelpOpen = isOpen
  })
}

export const setPrintOpen = (isOpen: boolean) => {
  store.produce((draft) => {
    draft.isPrintOpen = isOpen
  })
}

export const setSignOpen = (isOpen: boolean) => {
  store.produce((draft) => {
    draft.isSignOpen = isOpen
  })
}

export const setSignHistoryOpen = (isOpen: boolean) => {
  store.produce((draft) => {
    draft.isSignHistoryOpen = isOpen
  })
}

export const setSignMoreOpen = (isOpen: boolean) => {
  const { kioskMode } = store.state

  store.produce((draft) => {
    draft.isSignMoreOpen = isOpen
  })

  if (!isOpen && kioskMode) {
    startAutoSignOut('signature')
  }
}

// Kiosk Mode -----------------------------------------------------------------

export const resetAutoSignOut = () => {
  store.produce((draft) => {
    // biome-ignore lint/performance/noDelete: only executed once on demand
    delete draft.autoSignOut
  })
}

export const startAutoSignOut = (reason: string) => {
  store.produce((draft) => {
    draft.autoSignOut = reason
  })
}

// Reading --------------------------------------------------------------------

// Mark this handbook section as read
export async function dismissAnnotations(annotationIds: string[]) {
  const { session } = store.state

  // Read the section (if live)
  if (isLive && !session.isImpersonating) {
    await api.dismissAnnotations(annotationIds)
  }

  // Remove from store
  store.produce((draft) => {
    const { handbook } = draft
    handbook.annotations = handbook.annotations.filter(
      (a) => !annotationIds.includes(a.id),
    )
  })
}

// Signing  --------------------------------------------------------------------

export type PreviewModeType = 'signing' | 'viewing'

export const setPreviewMode = (mode: PreviewModeType) => {
  patchQuery({ mode })

  if (mode === 'signing' && acknowledgementForm.forceSign) {
    setSignOpen(true)
  }
}

export const signHandbook = async (signName: string) => {
  const { handbook, handbookSessionId, kioskMode, otherHandbooks, session } =
    store.state
  const { forceSign } = handbook.acknowledgementForm

  // Actually sign the handbook (if live)
  if (isLive && !session.isImpersonating) {
    const signature = await api.signHandbookSession({
      handbookId: handbook.id,
      handbookSessionId,
      input: {
        languageCode: handbook.languageCode,
        signature: signName,
      },
      signatureRoundId: handbook.signatureRoundId,
    })

    // Update the store
    store.produce((draft) => {
      // Add the signature to the store
      draft.handbook.signatures.push(signature)

      // Clear signing state
      draft.handbook.annotations = []
      draft.handbook.signatureRoundId = undefined
      draft.isSignatureRequired = false
    })
  }

  // Close the sign modal
  setSignOpen(false)

  // Open sign more (if there are some)
  if (
    otherHandbooks.some((handbook) =>
      handbook.permissionIds.includes('handbook.sign'),
    )
  ) {
    setSignMoreOpen(true)
  }
  // Auto sign out (if kiosk mode, NOT force sign)
  else if (kioskMode && !forceSign) {
    startAutoSignOut('signature')
  }
}

// Preferences  ---------------------------------------------------------------

export async function setDefaultLanguageCode(languageCode: string) {
  const { session } = store.state

  // Actually set the preferemce (if live)
  if (isLive && !session.isImpersonating) {
    await setPreferences({ defaultLanguageCode: languageCode })
  }

  // Set the preference in the UI
  store.produce((draft) => {
    draft.preferences.defaultLanguageCode = languageCode
  })

  // If the selected language is different from the rendered language, then we need to reload
  if (languageCode !== handbook.languageCode) {
    window.location.reload()
  }
  // Otherwise, close the language selector
  else {
    store.produce((draft) => {
      draft.isLanguageOpen = false
    })
  }
}
